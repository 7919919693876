import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Info from "../components/info"
import HomeScreenshots from "../components/homeScreenshots"
import Brands from "../components/brands"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

function index() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Zey Ventures</title>
      </Helmet>
      <Appbar />
      <Info />
      <HomeScreenshots />
      <Brands />
      <Footer />
    </div>
  )
}

export default index

import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default function HomeScreenshots() {
  const { fluid } = useScreenshots()
  return (
    <section className="py-8 lg:py-20">
      <div className="container mx-auto px-4">
        <h2
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-easing="ease"
          className="text-4xl lg:text-6xl font-serif"
        >
          Available on all devices. <br></br>
          <span className="text-primary">Immediately.</span>
        </h2>
      </div>

      <div className="flex flex-wrap">
        <div className="flex-initial w-1/2 pr-8 hidden lg:block">
          <svg
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="ease"
            className="w-full"
            width="635"
            height="560"
            viewBox="0 0 635 560"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-107 555.5L50 318L142 412.5L279.5 218L388 269.5L594.5 43"
              stroke="black"
              stroke-width="14"
            />
            <path d="M610 64.5L573 31L635 0L610 64.5Z" fill="black" />
          </svg>
        </div>
        <div className="w-full pl-20 lg:pl-0 lg:w-1/2 mt-4 lg:-mt-32 -mb-8 lg:-mb-24">
          <Image
            className="lg:max-w-full"
            fluid={fluid}
            style={{ width: "600" }}
          />
        </div>
      </div>
    </section>
  )
}

export const useScreenshots = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "screenshots.png" }) {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const fluid = file && file.childImageSharp && file.childImageSharp.fluid

  return { fluid }
}

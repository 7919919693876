import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
// import Link from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"

export default function Info() {
  const { fixed } = useThemeIcon()
  return (
    <div className="bg-secondary-light p-8 lg:p-32">
      <div className="container mx-auto text-center">
        <Image
          className="max-w-full"
          fixed={fixed}
          style={{ width: "120px", height: "120px" }}
        />
        <h1 className="max-w-4xl mx-auto text-3xl lg:text-5xl font-serif">
          High-quality blogs, reviews and guides.<br></br>Available for free.
        </h1>
        <div className="py-8 lg:py-12">
          <Link
            className="bg-primary transition hover:bg-primary-dark text-white py-2 px-4 lg:px-8 rounded-lg"
            to="/projects"
          >
            View Sites
          </Link>
        </div>
      </div>
    </div>
  )
}

export const useThemeIcon = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "icon-with-shadow.png" }) {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const fixed = file && file.childImageSharp && file.childImageSharp.fixed

  return { fixed }
}
